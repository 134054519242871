<template>
  <div>
    <b-modal
      id="modal-login"
      no-close-on-backdrop
      size="lg"
      centered
      ok-only
      hide-footer="true"
      title="Fundraiser"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              ref="image"
              label="Image"
              invalid-feedback="Image is required."
            >
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i
                        @click="deleteLogo()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="myObj.image"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="salesforceID">
              <h5>{{ myObj.saleforceID }}</h5>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Title" invalid-feedback="Title is required.">
          <b-form-input
            ref="name"
            placeholder="Enter Title here."
            required
            @focusout="CheckName()"
            v-model="myObj.title"
          />
        </b-form-group>

        <!-- <b-form-group label="Description" invalid-feedback="Description is required." ref="desc">
          <b-form-textarea v-model.trim="myObj.description" placeholder="Enter description here" />
        </b-form-group> -->

        <b-row>
          <b-col md="4">
            <b-form-group
              label="Amount"
              invalid-feedback="Amount is required."
              ref="amount"
            >
              <b-form-input
                type="number"
                placeholder="Enter fund amount"
                v-model="myObj.fundAmount"
                @focusout="CheckAmount()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Valid From"
              invalid-feedback="Date is required."
              ref="fdate"
            >
              <flat-pickr
                v-model="myObj.validFrom"
                :config="{
                  altFormat: 'j M, Y',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                }"
                class="form-control"
                placeholder="Select date."
                @on-change="checkFDate()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Valid Till"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                v-model="myObj.validTill"
                :config="{
                  altFormat: 'j M, Y',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                }"
                class="form-control"
                placeholder="Select date."
                @on-change="checkDate()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Button Title"
              invalid-feedback="Button Title is required."
              ref="button"
            >
              <b-form-input
                v-model.trim="myObj.buttonTitle"
                placeholder="Enter button title"
                @focusout="checkButton()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Redirect To"
              invalid-feedback="Redirect To is required."
            >
              <v-select
                v-model="myObj.actionType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="actionOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="myObj.actionType == 'page'">
            <b-form-group
              label="Page"
              invalid-feedback="Page is required."
              ref="page"
            >
              <v-select
                v-model="myObj.pageID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                label="title"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="select page"
                @input="checkPage()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-else-if="myObj.actionType == 'url'">
            <b-form-group
              label="URL"
              invalid-feedback="URL is required."
              ref="url"
            >
              <b-form-input
                v-model.trim="myObj.url"
                placeholder="Enter url"
                @focusout="CheckUrl()"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <br />
      <div style="">
        <b-button
          class="float-right ml-5"
          variant="primary"
          @click="AddFlavour()"
          :disabled="request"
        >
          <b-spinner
            v-if="request"
            variant="light"
            type="grow"
            small
            label="Spinning"
          ></b-spinner>
          <span v-if="!request" class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col class="mt-2">
            <div
              class="d-md-flex align-items-center justify-content-start"
              style="gap: 1.5rem"
            >
              <div>
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Fundraiser</span>
                </b-button>
              </div>
              <b-form-input
                style="flex: 1;"
                v-model="searchQuery"
                class="d-inline-block mt-1 mt-md-0"
                placeholder="Search by title"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table
          :items="filters"
          :fields="fields"
          show-empty
          responsive
          :busy="dataLoading"
        >
          <template #table-busy>
            <div class="text-center my-5">
              <b-spinner
                class="align-middle"
                type="grow"
                variant="primary"
                style="width: 3.5rem; height: 3.5rem;"
              ></b-spinner>
            </div>
          </template>

          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(image)="data">
            <b-img
              :src="data.value"
              style="width: 100px; height: 100px; object-fit: cover"
              rounded
              alt="image"
            />
          </template>

          <template #cell(validTill)="data">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Edit"
                @click="OpenAddModal(data.item)"
              >
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete"
                size="16"
                @click="DeleteFlavour(data.item.id)"
              >
                <feather-icon size="16" icon="XIcon" />
              </b-button>
            </template>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
      this.LoadPages();
    }
  },
  computed: {
    filters: function() {
      return this.items.filter((pro) => {
        return pro.title.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      plus: "",
      fileProfile: "",
      logoloading: false,
      fields: [
        "#",
        { label: "image", key: "image" },
        { label: "Title", key: "title" },
        { label: "raising amount", key: "fundAmount" },
        { label: "valid Till", key: "validTill" },
        { label: "salesforceID", key: "saleforceID" },
        "actions",
      ],
      items: [],
      request: false,
      myObj: {
        id: 0,
        title: "",
        image: "",
        description: "",
        donationCount: 0,
        validTill: "",
        fundAmount: 0,
        collectedAmount: 0,
      },
      actionOptions: [
        { text: "Page", value: "page" },
        { text: "URL", value: "url" },
      ],
      pageOptions: [],
      searchQuery: "",
      dataLoading: false,
    };
  },
  methods: {
    getPageTitle(id) {
      let obj = this.pageOptions.find((el) => el.id == id);
      return obj ? obj.title.replace(/\s+/g, "-").toLowerCase() : "";
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAmount() {
      var elem = this.$refs["amount"];
      if (this.myObj.fundAmount == "" || parseInt(this.myObj.fundAmount) <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDate() {
      var elem = this.$refs["date"];
      if (
        !this.myObj.validTill ||
        this.myObj.validTill < this.myObj.validFrom
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkFDate() {
      var elem = this.$refs["fdate"];
      if (
        !this.myObj.validFrom ||
        this.myObj.validFrom > this.myObj.validTill
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkButton() {
      var elem = this.$refs["button"];
      if (!this.myObj.buttonTitle) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPage() {
      if (this.myObj.actionType == "page") {
        var elem = this.$refs["page"];
        if (this.myObj.pageID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckUrl() {
      if (this.myObj.actionType == "url") {
        var elem = this.$refs["url"];
        if (!this.myObj.url) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckImage() {
      var elem = this.$refs["image"];
      if (this.myObj.image == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckStatus() {
      var elem = this.$refs["status"];
      if (this.myObj.status == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          title: "",
          image: "",
          description: "",
          donationCount: 0,
          validTill: "",
          validFrom: "",
          fundAmount: 0,
          collectedAmount: 0,
          buttonTitle: "",
          actionType: "page",
          pageID: 0,
          url: "",
          donationLink: "",
          saleforceID: "",
        };
        this.logoloading = false;
        this.plus =
          "https://cdn.cloudious.net/file-1678189226453-406060527.png";
        this.$bvModal.show("modal-login");
      } else {
        this.myObj = { ...id };
        if (this.myObj.image) {
          this.logoloading = "loaded";
        } else {
          this.logoloading = false;
        }
        this.$bvModal.show("modal-login");
      }
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/Fundraiser/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Fundraiser has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      // console.log(this.fileProfile);
      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            this.plus = e.data.myresp[0].path;

            this.myObj.image = this.plus;
            // console.log(this.plus);
            this.logoloading = "loaded";
            this.CheckImage();
          })
          .catch((err) => {
            this.logoloading = false;
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.image = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    AddFlavour() {
      this.CheckImage();
      this.CheckName();
      this.checkDate();
      this.checkFDate();
      this.CheckAmount();
      this.checkButton();
      this.checkPage();
      this.CheckUrl();
      if (
        this.CheckImage() == false ||
        this.CheckName() == false ||
        this.checkDate() == false ||
        this.checkFDate() == false ||
        this.CheckAmount() == false ||
        this.checkButton() == false ||
        this.checkPage() == false ||
        this.CheckUrl() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log("Obj", this.myObj);
        this.request = true;
        this.myObj.fundAmount = parseInt(this.myObj.fundAmount);

        if (this.myObj.actionType == "page") {
          let tt = this.getPageTitle(this.myObj.pageID);
          this.myObj.donationLink = `https://worldappeal.org.uk/page/${
            tt ? tt : this.myObj.pageID
          }`;
        } else if (this.myObj.actionType == "url") {
          this.myObj.donationLink = this.myObj.url;
        } else this.myObj.donationLink = "";

        var axios = require("axios");
        var config = {
          method: "post",
          url: "https://api.worldappeal.org.uk/api/Fundraiser/save",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.myObj,
        };
        axios(config)
          .then((response) => {
            this.request = false;
            if (response.data.status === "success") {
              this.$bvToast.toast("Fundraiser saved successfully!", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-top-center",
              });
              this.LoadData();
              this.$bvModal.hide("modal-login");
            } else {
              this.$bvToast.toast("Something went wrong.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-bottom-center",
              });
            }
          })
          .catch(function(error) {
            this.request = false;
            console.log(error);
          });
      }
    },
    LoadData() {
      this.dataLoading = true;
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Fundraiser",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.items = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadPages() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.pageOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
